<template>
  <div class="scientific">
      <div class="banner">
        <div class="container bannerContainer">
          <div>
            <p><strong>科研诚信</strong>系统</p>
            <span>打通学术科研活动“事前-事中-事后”</span>
            <span>全链条覆盖的科研诚信建设与学术不端治理闭环的产品服务体系</span>
          </div>
          <img class="bannerBg" src="../../assets/images/scientificBanner2.png" alt="">
        </div>
        <div class="bannerContent">
          <div class="container">
            <ul>
              <li><img src="../../assets/images/scientificIcon01.png" alt=""><span>检测预警服务</span></li>
              <li><img src="../../assets/images/scientificIcon03.png" alt=""><span>诚信学堂学习</span></li>
              <li><img src="../../assets/images/scientificIcon02.png" alt=""><span>学术诚信档案</span></li>
              <li><img src="../../assets/images/scientificIcon04.png" alt=""><span>万方检测</span></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="content3">
        <div class="title">科研诚信动态</div>
        <div class="container">
           <div class="menu">
             <div>
              <span :class="{'active': menuIndex == '1'}" @click="menuIndex = '1',init()">所有动态</span>
              <span :class="{'active': menuIndex == '2'}" @click="menuIndex = '2',init()">核心政策</span>
              <span :class="{'active': menuIndex == '3'}" @click="menuIndex = '3',init()">诚信案例</span>
             </div>
             <router-link to="/ScientificList">查看更多<span class="icon iconfont">&#xe656;</span></router-link>
           </div>
          <a-row>
            <a-col class="content3L" :md="24" :lg="12" :xl="12" v-for="(item,index) in newsList" :key="index">
              <div class="content3Box">
                <div><p>{{item.createTimeText.substring(5,10)}}</p><span>{{item.createTimeText.substring(0,4)}}</span></div>
                <div><p>{{item.noticeTitle}}</p><span>{{item.noticeContent.content}}</span><router-link :to="{path: '/ScientificDetail',query: {id: item.id}}">查看详情</router-link></div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>

      <div class="content1">
        <div class="container">
          <a-row>
            <a-col class="content1L" :md="24" :lg="11" :xl="11">
              <div class="title">科研诚信产品服务</div>
              <p>以国家及地方科研诚信建设要求为依托，结合高校及其他科研机构针对学术主体进行科研诚信教育、监测、管理需求，万方科技科研诚信产品服务体系是从公司战略角度布局建设的专项科研诚信信息服务产品生态圈。</p>
              <p>产品服务基于科研管理服务全流程视域，致力于打造集“事前教育培训服务、事中监测预警、事后调查处理”为一体的科研诚信服务闭环，围绕教育科研、科技管理、成果发表等三大场景重点研发教育培训、检测查重、检测预警、核查评价等产品服务，努力为用户提供一站式、链条式的科研诚信产品集成与融合服务。</p>
            </a-col>
            <a-col :md="0" :lg="3" :xl="3"></a-col>
            <a-col class="content1R" :md="24" :lg="10" :xl="10">
              <img src="../../assets/images/scientificImg01.png" alt="">
            </a-col>
          </a-row>
        </div>
      </div>

      <div class="content2">
        <div class="container">
          <div class="title">科研诚信产品&服务</div>
          <a-row>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <img src="../../assets/images/scientificIcon05.png" alt="">
              <p>科研诚信机构</p>
              <span>为高等院校及其他科研机构研发设计展示与对外宣传平台模板，定期对平台信息进行更新和完善，支持机构用户自定义页面展示内容，能够最大限度的提高科研诚信建设效率及网站内容管理效率。</span>
            </a-col>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <img src="../../assets/images/scientificIcon05.png" alt="">
              <p>知识资源</p>
              <span>深度聚合、标引与关联科研诚信主题相关的信息资源，为高校、科研单位、政府机构、行业协会等多机构开展科研诚信研究、教学及科研活动管理提供基础知识信息支持。</span>
            </a-col>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <img src="../../assets/images/scientificIcon05.png" alt="">
              <p>教育培训</p>
              <span>以国家科研诚信建设顶层设计文件为指导，从“事前教育培训”角度出发打造支持机构科研诚信教育培训工作的服务产品，辅助机构全面提升人才的科研诚信素养。</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <img src="../../assets/images/scientificIcon05.png" alt="">
              <p>监测预警</p>
              <span>及时发现科研不端风险、行为并进行处理，管控风险，着力成为教育科研机构与管理部门科研诚信建设的主要抓手。辅助教育科研机构发现潜在的苗头性、倾向性问题与不端行为、妥当处理、主动规避风险，避免造成更大的危害与负面舆情。</span>
            </a-col>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <img src="../../assets/images/scientificIcon05.png" alt="">
              <p>核查评价</p>
              <span>基于科研管理、学术出版发行、学术成果审查等多场景，核查学术成果、行为主体等多个方面，辅助机构做好科研诚信评估审查工作。</span>
            </a-col>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <img src="../../assets/images/scientificIcon05.png" alt="">
              <p>检测查重</p>
              <span>基于海量的文献数据比对资源，采用先进检测技术，向学术个体和学术机构提供综合性、专业性、集成化、精细化的文献相似性检测服务。</span>
            </a-col>
          </a-row>
        </div>
      </div>
  </div>
</template>

<script>
import { queryContentList } from '@/api/EvidenceService'
export default {
  components: {},
  data () {
    return {
      menuIndex: 1,
      newsList: [],
    }
  },
  created () {
    this.init();
  },
  methods: {
    init(){
      let type = '';
      if(this.menuIndex == '1'){
        type = '';
      }else if(this.menuIndex == '2'){
        type = '1'
      }else if(this.menuIndex == '3'){
        type = '2'
      }
      let params = {
        type: type,
      }
      queryContentList(1,4,params).then(({data}) => {
        // console.log(data);
        for (let i = 0; i < data.list.length; i++) {
          data.list[i].createTimeText= this.dayjs(data.list[i].createTime*1000).format('YYYY/MM/DD HH:mm:ss ');
        }
        this.newsList = data.list
      })
    },
    
  },
}
</script>

<style lang="scss" scoped>
.banner{
  width: 100%;
  height: 400px;
  position: relative;
  background: url(../../assets/images/scientificBanner.png)no-repeat;
  background-size: cover;
  .bannerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      p{
        font-size: 52px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #000000;
        line-height: 81px;
        strong{
          color: #316EF4;
        }
      }
      span{
        display: block;
        font-size: 20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #163B61;
        line-height: 40px;
      }
    }
    img{
      height: 300px;
      margin: 40px 0 60px 0;
    }
  }
  .bannerContent{
    width: 100%;
    position: absolute;
    bottom: -80px;
    ul{
      width: 100%;
      height: 140px;
      background: linear-gradient(180deg, #F2F9FF 0%, #FFFFFF 100%);
      box-shadow: 0px 4px 18px 0px rgba(184,205,251,0.26);
      display: flex;
      align-items: center;
      li{
        display: block;
        width: 25%;
        height: 100%;
        position: relative;
        img{
          height: 60px;
          display: block;
          margin: 24px auto 0 auto;
        }
        span{
          display: block;
          font-size: 18px;
          font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
          font-weight: 500;
          color: #000000;
          line-height: 30px;
          text-align: center;
        }
      }
      li::after{
        content:'';
        width: 1px;
        height: 100px;
        background: #E6E9EE;
        position: absolute;
        right: 0;
        top: 20px;
      }
      li:last-child::after{
        display: none;
      }
    }
  }
  
}
.title{
  font-size: 36px;
  font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
  font-weight: 500;
  color: #0A3372;
  line-height: 54px;
}
.content1{
  background: #FFFFFF;
  padding: 40px 0;
  .content1L{
    .title{
      margin-top: 50px;
    }
    p{
      font-size: 14px;
      font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
      font-weight: 400;
      color: #596178;
      line-height: 28px;
      margin-top: 30px;
    }
  }
  .content1R{
    img{
      width: 100%;
    }
  }
}
.content2{
  padding: 40px 0;
  background: url(../../assets/images/scientificContent2Bg.png)no-repeat;
  background-size: cover;
  .title{
    text-align: center;
  }
  .content2Box{
    text-align: center;
    padding: 40px 30px;
    height: 320px;
    img{
      width: 80px;
      height: 60px;
    }
    p{
      font-size: 16px;
      font-family: 'SourceHanSansCN-Bold, SourceHanSansCN';
      font-weight: bold;
      color: #000000;
      line-height: 24px;
      margin: 15px 0 20px 0;
    }
    span{
      font-size: 14px;
      font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
      font-weight: 400;
      color: #596178;
      line-height: 20px;
    }
  }
}
.content3{
  padding: 0 0 40px 0;
  background: #F7F7F7;
  .title{
    text-align: center;
    padding-top: 120px;
    padding-bottom: 30px;
    background: #FFFFFF;
  }
  .menu{
      border-bottom: 1px solid #DCDCDC;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      div{
        span{
          display: inline-block;
          width: 160px;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          font-family: 'SourceHanSansCN, SourceHanSansCN';
          color: #000000;
          cursor: pointer;
        }
        span.active{
          color: #1353B4;
        }
      }
      a{
        font-size: 14px;
        font-family: 'SourceHanSansCN, SourceHanSansCN';
        color: #666666;
        display: flex;
        align-items: center;
        span{
          opacity: 0.7;
          font-size: 14px;
        }
      }
    }
  .content3L{
    // padding: 0 15px;
    .content3Box{
      margin-top: 30px;
      background: #FFFFFF;
      padding: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:nth-child(1){
        width: 70px;
        padding-right: 30px;
        p{
          font-size: 26px;
          // font-family: 'DINAlternate, DINAlternate';
          font-family: 'DIN';
          font-weight: 500;
          color: #333333;
          line-height: 32px;
        }
        span{
          font-size: 14px;
          font-family: 'MicrosoftYaHei';
          color: #808192;
          line-height: 16px;
        }
      }
      div:nth-child(2){
        width: calc(100% - 100px);
        padding-left: 30px;
        position: relative;
        p{
          font-size: 16px;
          font-family: 'SourceHanSansCN, SourceHanSansCN';
          font-weight: 500;
          color: #000000;
          line-height: 24px;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        span{
          font-size: 14px;
          font-family: 'SourceHanSansCN, SourceHanSansCN';
          font-weight: 400;
          color: #999999;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        a{
          display: block;
          margin-top: 15px;
          font-size: 12px;
          font-family: 'SourceHanSansCN, SourceHanSansCN';
          font-weight: 400;
          color: #1353B4;
        }
      }
      div:nth-child(2)::before{
        content: '';
        width: 1px;
        height: 100%;
        background: #E1E1E1;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .content3L:nth-child(2n-1){
    padding-right: 15px;
  }
  .content3L:nth-child(2n){
    padding-left: 15px;
  }
}
// 适配移动端
@media screen and (max-width: 991px){
  .bannerContainer{
    position: relative;
    padding: 50px 20px;
    img{
      display: none;
      position: absolute;
      left: 10%;
      top: 10%;
      z-index: 0;
      width: 80%;
      height: 80%!important;
    }
    div{z-index: 1;}
  }
  .title{
    font-size: 26px;
    padding: 0 20px;
  }
  .banner .bannerContent{
    bottom: -140px;
    ul{
      display: block;
      li{
        width: 50%;
        float: left;
        background: #FFFFFF;
      }
    } 
  } 
  .content3{
    padding-top: 180px;
    .title{
      padding: 0;
      background: transparent;
    }
    .menu{
      div{
        span{
          width: 100px;
        }
      }
    }
    .content3L:nth-child(2n-1){
        padding-right: 0;
      }
      .content3L:nth-child(2n){
        padding-left: 0;
      }
    .content3Box{
      padding: 15px!important;
      // div:nth-child(1){
      //   padding-right: 15px!important;
      // }
      // div:nth-child(2){
      //   padding-left: 15px!important;
      // }
    }
  }
}

</style>
